import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var HttpInterceptorService = /** @class */ (function () {
    function HttpInterceptorService(translate) {
        this.translate = translate;
        translate.defaultLang;
    }
    HttpInterceptorService.prototype.intercept = function (req, next) {
        return next.handle(this.appendHeaders(req));
    };
    HttpInterceptorService.prototype.appendHeaders = function (req) {
        return req.clone({
            setHeaders: {
                'language': this.translate.currentLang || '',
            },
        });
    };
    HttpInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(i0.inject(i1.TranslateService)); }, token: HttpInterceptorService, providedIn: "root" });
    return HttpInterceptorService;
}());
export { HttpInterceptorService };
