import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {

  constructor(
    private translate: TranslateService,
  ) {
    translate.defaultLang;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(this.appendHeaders(req));
  }

  appendHeaders(req: HttpRequest<any>) {
    return req.clone({
      setHeaders: {
        'language': this.translate.currentLang || '',
      },
    });
  }

}
